export default [
  {
    title: 'Reports',
    icon: 'FileTextIcon',
    tagVariant: 'light-success',
    children: [
      {
        title: 'My Reports',
        route: 'my-reports',
        icon: 'none',
      },
      {
        title: 'Downloads',
        route: '',
        icon: 'none',
      },
      {
        title: 'Software Workflow',
        route: 'software-step01',
        icon: 'none',
      },
      {
        title: 'Other',
        route: '',
        icon: 'none',
      },
    ],
  },
]
