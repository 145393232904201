export default [
  {
    header: 'Company',
  },
  
  {
    title: 'About us',
    route: '',
    icon: 'none',
  },
  {
    title: 'Contacts',
    route: '',
    icon: 'none',
  },
  {
    title: 'My account',
    route: { name: 'apps-users-edit', params: { id: 1 } },
    icon: 'none',
  },
]
