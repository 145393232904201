export default [
  {
    title: 'Software Workflow',
    icon: 'GitCommitIcon',
    children: [
      {
        title: 'Step 01',
        route: 'software-step01',
      },
      {
        title: 'Step 02',
        route: 'software-step02',
      },
      {
        title: 'Step 03',
        route: 'software-step03',
      },
      {
        title: 'Step 04',
        route: 'software-step04',
      },
      {
        title: 'Document Structure',
        route: 'software-structure',
      },
      {
        title: 'Dimension',
        route: 'software-dimension-list',
      },
      {
        title: 'Edit Dimension',
        route: 'software-dimension-edit',
      },
    ],
  },
]
