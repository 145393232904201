export default [
  {
    header: 'Support',
  },
  {
    title: 'Terms & Conditions',
    route: 'pages-faq',
    icon: 'none',
  },
  {
    title: 'Privacy Policy',
    route: 'pages-faq',
    icon: 'none',
  },
  {
    title: 'Cookie Policy',
    route: 'pages-faq',
    icon: 'none',
  },
  {
    title: 'FAQs',
    route: 'pages-faq',
    icon: 'none',
  },
]
